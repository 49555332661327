import React, { useState } from "react";
import "./Qualifications.css";

function Qualifications() {
  const [toggleState, setToggleState] = useState(1)

  const toggleTab = (index) => {
    setToggleState(index)
  }
  return (
    <section className="qualifications section" id="qualifications">
      <h2 className="section__title">Qualifications</h2>
      <span className="section__subtitle">My experience & education:</span>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={
              toggleState === 1
                ? "qualification__button qualification__active button--flex"
                : "qualification__button  button--flex"
            }
            onClick={() => toggleTab(1)}
          >
            <i className="uil uil-briefcase-alt qualification__icon"></i>
            Experience
          </div>

          <div
            className={
              toggleState === 2
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(2)}
          >
            <i className="uil uil-graduation-cap qualification__icon"></i>
            Education
          </div>
        </div>

        <div className="qualification__sections">
          <div
            className={
              toggleState === 1
                ? "qualification__content qualification__content-active"
                : "qualification__content "
            }
          >
            {/* -------------------------------- */}
            <div className="qualification__data">
            <div>
                <h3 className="qualification__title">Software Engineer</h3>
                <span className="qualification__subtitle">
                    Knowmad Mood
                </span>

                <ul className="qualification__list">
                    <li className="qualification__bullet">Migration of an eCommerce platform.</li>
                    <li className="qualification__bullet">Identiy and solve complex technical issues.</li>
                    <li className="qualification__bullet">Develop and maintain new features.</li>
                </ul>

                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i>JApr 2024 - present
                </div>
              </div>

              <div>
                <h3 className="qualification__title">Frontend Developer</h3>
                <span className="qualification__subtitle">
                    IsEazy LMS
                </span>

                <ul className="qualification__list">
                    <li className="qualification__bullet">Developed key features.</li>
                    <li className="qualification__bullet">Refactored & optimized store modules.</li>
                    <li className="qualification__bullet">Built extensive unit testing coverage.</li>
                </ul>

                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i>May 2021 - Apr 2024
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
            {/* -------------------------------- */}
          </div>

          {/* Education */}

          <div
            className={
              toggleState === 2
                ? "qualification__content qualification__content-active"
                : "qualification__content "
            }
          >
              {/* -------------------------------- */}
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Degree in Business Administration of Technological Companies</h3>
                <span className="qualification__subtitle">
                    Universidad Pontificia de Salamanca
                </span>

                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i>2017 - 2021
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
            {/* -------------------------------- */}
            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
              <h3 className="qualification__title">
                    Sciences du Numérique (ERASMUS)
                </h3>
                <span className="qualification__subtitle">
                    Université Catholique de Lille
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i>2019 - 2020
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Qualifications;
